import { TextCtaThreeColumnCard } from "../../molecules/TextCtaThreeColumnCard/TextCtaThreeColumnCard";

export class TextCtaThreeColumn {
    private element: HTMLElement;
    private cards: TextCtaThreeColumnCard[];

    constructor(element: HTMLElement) {
        this.element = element;
        this.cards = [];
        this.init();
    }

    private init(): void {
        const cardElements = this.element.querySelectorAll('[data-text-cta-three-column-card]');
        cardElements.forEach(cardElement => {
            if (cardElement instanceof HTMLElement) {
                this.cards.push(new TextCtaThreeColumnCard(cardElement));
            }
        });
    }
}
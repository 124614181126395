import { AccordionItem } from '../../molecules/AccordionItem/AccordionItem';

export class Accordion {
  private element: HTMLElement;
  private items: AccordionItem[];

  constructor(element: HTMLElement) {
    this.element = element;
    this.items = [];
    this.init();
  }

  private init(): void {
    const itemElements = this.element.querySelectorAll('[data-accordion-item]');
    itemElements.forEach(itemElement => {
      if (itemElement instanceof HTMLElement) {
        this.items.push(new AccordionItem(itemElement));
      }
    });
  }
}
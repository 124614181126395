import { truncateText } from '../../../scripts/utils/truncateText';

export class ImageCtaFullWidth {
    private element: HTMLElement;
    private descriptionElement: HTMLDivElement | null;
    private truncationLength: number = 300;

    constructor(element: HTMLElement) {
        this.element = element;
        this.descriptionElement = this.element.querySelector('.image-cta-full-width__description');
        this.init();
    }

    private init(): void {
        this.updateTruncation();
        window.addEventListener('resize', this.updateTruncation.bind(this));
    }

    private updateTruncation(): void {
        const windowWidth = window.innerWidth;
        if (windowWidth < 768) {
            this.truncationLength = 120;
        } else if (windowWidth < 992) {
            this.truncationLength = 220;
        } else if (windowWidth < 1340) {
            this.truncationLength = 180;
        } else {
            this.truncationLength = 250;
        }
        this.truncateDescription();
    }

    private truncateDescription(): void {
        if (this.descriptionElement) {
            const originalText = this.descriptionElement.dataset.fullText ?? '';
            const truncatedText = truncateText(originalText, this.truncationLength);
            this.descriptionElement.innerHTML = truncatedText;
        }
    }
}
export class AccordionItem {
    private element: HTMLElement;
    private titleElement: HTMLElement;
    private contentElement: HTMLElement;
  
    constructor(element: HTMLElement) {
      this.element = element;
      this.titleElement = element.querySelector('.accordion-item__title') as HTMLElement;
      this.contentElement = element.querySelector('.accordion-item__content') as HTMLElement;
      this.init();
    }
  
    private init(): void {
      this.titleElement.addEventListener('click', this.toggleAccordion);
    }
  
    private toggleAccordion = (): void => {
      this.element.classList.toggle('active');
    }
  
    public close(): void {
      this.element.classList.remove('active');
    }
  }
import { Button } from "../components/atoms/Button/Button";
import { ImageCtaThreeColumnCard } from "../components/molecules/ImageCtaThreeColumnCard/ImageCtaThreeColumnCard";
import { ImageCtaTwoColumnCard } from "../components/molecules/ImageCtaTwoColumnCard/ImageCtaTwoColumnCard";
import { TextCtaThreeColumnCard } from "../components/molecules/TextCtaThreeColumnCard/TextCtaThreeColumnCard";
import { GlobalHeader } from "../components/organisms/GlobalHeader/GlobalHeader";
import { HeroBannerWithCta } from "../components/organisms/HeroBannerWithCta/HeroBannerWithCta";
import { ImageCtaThreeColumn } from "../components/organisms/ImageCtaThreeColumn/ImageCtaThreeColumn";
import { ImageCtaTwoColumn } from "../components/organisms/ImageCtaTwoColumn/ImageCtaTwoColumn";
import { TextCtaThreeColumn } from "../components/organisms/TextCtaThreeColumn/TextCtaThreeColumn";
import { Accordion } from "../components/organisms/Accordion/Accordion";
import { QuickLinks } from "../components/organisms/QuickLinks/QuickLinks";
import { ImageCtaFullWidth } from "../components/organisms/ImageCtaFullWidth/ImageCtaFullWidth";


document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('[data-button]').forEach(button => {
        new Button(button as HTMLButtonElement);
    });

    document.querySelectorAll('[data-hero-banner-cta]').forEach(banner => {
        new HeroBannerWithCta(banner as HTMLElement);
    });

    document.querySelectorAll('[data-image-cta-three-column]').forEach(component => {
        new ImageCtaThreeColumn(component as HTMLElement);
    });

    document.querySelectorAll('[data-image-cta-three-column-card]').forEach(card => {
        new ImageCtaThreeColumnCard(card as HTMLElement);
    });

    document.querySelectorAll('[data-image-cta-two-column]').forEach(component => {
        new ImageCtaTwoColumn(component as HTMLElement);
    });

    document.querySelectorAll('[data-image-cta-two-column-card]').forEach(card => {
        new ImageCtaTwoColumnCard(card as HTMLElement);
    });

    document.querySelectorAll('[data-text-cta-three-column]').forEach(component => {
        new TextCtaThreeColumn(component as HTMLElement);
    });

    document.querySelectorAll('[data-text-cta-three-column-card]').forEach(card => {
        new TextCtaThreeColumnCard(card as HTMLElement);
    });

    document.querySelectorAll('[data-global-header]').forEach(header => {
        new GlobalHeader(header as HTMLElement);
    });

    document.querySelectorAll('[data-accordion]').forEach(accordion => {
        new Accordion(accordion as HTMLElement);
    });

    document.querySelectorAll('[data-quick-links]').forEach(quickLinks => {
        new QuickLinks(quickLinks as HTMLElement);
    });

    document.querySelectorAll('[data-image-cta-full-width]').forEach(component => {
        new ImageCtaFullWidth(component as HTMLElement);
    });
});
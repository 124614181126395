export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

export const createDebouncedTruncateText = (delay: number = 250) => {
  let debounceTimer: number;

  return (text: string, maxLength: number, callback: (truncatedText: string) => void): void => {
    clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(() => {
      const truncatedText = truncateText(text, maxLength);
      callback(truncatedText);
    }, delay);
  };
};
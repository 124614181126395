import { Button } from '../../atoms/Button/Button';

export class QuickLinks {
    private element: HTMLElement;
    private buttons: Button[];

    constructor(element: HTMLElement) {
        this.element = element;
        this.buttons = [];
        this.init();
    }

    private init(): void {
        const buttonElements = this.element.querySelectorAll('[data-button]');
        buttonElements.forEach(buttonElement => {
            if (buttonElement instanceof HTMLButtonElement) {
                this.buttons.push(new Button(buttonElement));
            }
        });
    }
}
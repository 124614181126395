export class GlobalHeader {
    private element: HTMLElement;
    private hamburgerButton: HTMLElement | null;
    private menu: HTMLElement | null;
    private menuItems: NodeListOf<HTMLElement>;
    private megaMenuContainer: HTMLElement | null;
    private megaMenus: NodeListOf<HTMLElement>;
    private tabletMegaMenuContainer: HTMLElement | null;
    private tabletMegaMenuColumns: NodeListOf<HTMLElement>;
    private tabletBackButton: HTMLElement | null;
    private menuOpen: boolean = false;
    private hamburgerOpen: boolean = false;
    private activeMenuItem: HTMLElement | null = null;
    private currentTabletLevel: number = 1;
    private menuTimeout: number | null = null;
    private searchToggle: HTMLElement | null;
    private searchOverlay: HTMLElement | null;
    private isMobile(): boolean {
        return window.innerWidth < 768;
    }

    constructor(element: HTMLElement) {
        this.element = element;
        this.hamburgerButton = this.element.querySelector('[data-hamburger]');
        this.menu = this.element.querySelector('[data-menu]');
        this.menuItems = this.element.querySelectorAll('.menu-item');
        this.megaMenuContainer = this.element.querySelector('.mega-menu-container');
        this.megaMenus = this.element.querySelectorAll('.mega-menu');
        this.tabletMegaMenuContainer = this.element.querySelector('.tablet-mega-menu-container');
        this.tabletMegaMenuColumns = this.element.querySelectorAll('.tablet-mega-menu-column');
        this.tabletBackButton = this.element.querySelector('.tablet-back-button');
        this.searchToggle = this.element.querySelector('[data-search-toggle]');
        this.searchOverlay = document.querySelector('[data-search-overlay]') as HTMLElement | null;
        this.init();
    }

    private init(): void {
        this.hamburgerButton?.addEventListener('click', this.toggleMenu.bind(this));
        document.addEventListener('click', this.handleOutsideClick.bind(this));
        window.addEventListener('resize', this.handleResize.bind(this));
        this.initialiseMenuItems();
        this.initialiseMegaMenu();
        this.initialiseTabletMegaMenu();
        this.initialiseSearchOverlay();
        this.initialiseSearchForm();
    }

    private initialiseSearchOverlay(): void {
        if (this.searchToggle && this.searchOverlay) {
            this.searchToggle.addEventListener('click', this.toggleSearchOverlay.bind(this));
            this.searchOverlay.addEventListener('click', this.handleOverlayClick.bind(this));
        }
    }

    private toggleSearchOverlay(): void {
        const isActive = this.searchToggle?.classList.contains('active');
        if (isActive) {
            // Hide overlay
            this.searchOverlay?.setAttribute('hidden', 'true');
            this.searchToggle?.classList.remove('active');
        } else {
            // Show overlay
            this.searchOverlay?.removeAttribute('hidden');
            this.searchToggle?.classList.add('active');
        }
    }

    private initialiseSearchForm(): void {
        const searchForm = document.getElementById('overlay-search-form') as HTMLFormElement;
        if (searchForm) {
            searchForm.addEventListener('submit', (event) => {
                event.preventDefault();
                const searchInput = searchForm.querySelector('.search-input') as HTMLInputElement;
                const query = searchInput.value.trim();
                if (query) {
                    window.location.href = `/search?q=${encodeURIComponent(query)}`;
                }
            });
        }
    }

    private handleOverlayClick(event: MouseEvent): void {
        if (event.target === this.searchOverlay) {
            this.searchOverlay?.setAttribute('hidden', 'true');
            this.searchToggle?.classList.remove('active');
        }
    }

    private toggleMenu(): void {
        this.menuOpen = !this.menuOpen;
        this.hamburgerOpen = !this.hamburgerOpen;
        this.updateMenuState();
        this.updateHamburgerState();
    }

    private updateMenuState(): void {
        this.menu?.classList.toggle('active', this.menuOpen);
        if (this.tabletMegaMenuContainer) {
            this.tabletMegaMenuContainer.style.display = this.menuOpen ? 'block' : 'none';
        }
        if (this.menuOpen) {
            this.showTabletMegaMenuLevel(1);
        } else {
            this.resetTabletMegaMenu();
        }
    }

    private updateHamburgerState(): void {
        if (this.hamburgerButton) {
            this.hamburgerButton.classList.toggle('open', this.hamburgerOpen);
        }
    }

    private handleOutsideClick(event: MouseEvent): void {
        if (this.menuOpen && this.menu && !this.element.contains(event.target as Node)) {
            this.menuOpen = false;
            this.hamburgerOpen = false;
            this.updateMenuState();
            this.updateHamburgerState();
        }

        // Close search overlay if clicked outside
        if (
            this.searchOverlay &&
            !this.searchOverlay.contains(event.target as Node) &&
            !this.searchToggle?.contains(event.target as Node) &&
            !this.searchOverlay.hasAttribute('hidden')
        ) {
            this.searchOverlay.setAttribute('hidden', 'true');
            this.searchToggle?.classList.remove('active');
        }
    }

    private handleResize(): void {
        if (window.innerWidth > 768) {
            if (this.menuOpen) {
                this.menuOpen = false;
                this.hamburgerOpen = false;
                this.updateMenuState();
                this.updateHamburgerState();
            }
            this.resetTabletMegaMenu();
        }
    }

    private initialiseMenuItems(): void {
        this.menuItems.forEach((item) => {
            const link = item.querySelector('a');
            const expandMenu = item.getAttribute('data-expand-menu') === 'true';
            const hasSubitems = item.getAttribute('data-has-subitems') === 'true';
            const isMainMenuItem = item.closest('.bottom-row') !== null;

            if (link) {
                item.addEventListener('mouseenter', () => {
                    if (isMainMenuItem && hasSubitems && expandMenu) {
                        this.showMegaMenu(item);
                    } else {
                        this.scheduleHideMegaMenu();
                    }
                });

                item.addEventListener('mouseleave', () => {
                    this.scheduleHideMegaMenu();
                });
            }
        });

        const menuContainer = this.element.querySelector('.bottom-row');
        if (menuContainer) {
            menuContainer.addEventListener('mouseleave', () => {
                this.scheduleHideMegaMenu();
            });
        }
    }

    private initialiseMegaMenu(): void {
        if (this.megaMenuContainer) {
            this.megaMenuContainer.addEventListener('mouseenter', () => {
                this.cancelHideMegaMenu();
            });

            this.megaMenuContainer.addEventListener('mouseleave', () => {
                this.scheduleHideMegaMenu();
            });

            const subMenuItems = this.megaMenuContainer.querySelectorAll('.submenu-level-1 > li, .submenu-level-2 > li');
            subMenuItems.forEach(item => {
                item.addEventListener('mouseenter', () => {
                    this.showSubMenu(item as HTMLElement);
                });
            });
        }
    }

    private initialiseTabletMegaMenu(): void {
        if (this.tabletMegaMenuContainer) {
            const allTabletMenuItems = this.tabletMegaMenuContainer.querySelectorAll('li');
            allTabletMenuItems.forEach(item => {
                const link = item.querySelector('a');
                const arrowIcon = item.querySelector('.arrow-icon');

                if (link) {
                    link.addEventListener('click', (e) => {
                        if (!arrowIcon) {
                            return;
                        }
                        e.preventDefault();
                        this.handleTabletMenuItemClick(item as HTMLElement);
                    });
                }
            });

            if (this.tabletBackButton) {
                this.tabletBackButton.addEventListener('click', this.handleTabletBackButtonClick.bind(this));
            }
        }
    }

    private handleTabletMenuItemClick(item: HTMLElement): void {
        const currentLevel = parseInt(item.closest('.tablet-mega-menu-column')?.classList[1].replace('level-', '') || '1');
        const nextLevel = currentLevel + 1;
        const maxLevel = this.tabletMegaMenuColumns.length;

        if (nextLevel <= maxLevel) {
            const menuItemName = item.querySelector('a')?.textContent?.trim() || '';
            const menuItemUrl = item.querySelector('a')?.getAttribute('href') || '#';
            const menuItemId = item.getAttribute('data-item-id') || '';

            this.filterTabletSubmenuItems(menuItemId, nextLevel);
            this.showTabletMegaMenuLevel(nextLevel);

            const nextColumn = this.tabletMegaMenuColumns[nextLevel - 1];
            if (nextColumn) {
                nextColumn.classList.add('show-left-border');

                let viewAllItem = nextColumn.querySelector('.view-all-item');
                if (!viewAllItem) {
                    viewAllItem = document.createElement('li');
                    viewAllItem.classList.add('view-all-item');
                    viewAllItem.innerHTML = `<a href="${menuItemUrl}">View All ${menuItemName}</a>`;
                    nextColumn.querySelector('ul')?.appendChild(viewAllItem);
                } else {
                    (viewAllItem.querySelector('a') as HTMLAnchorElement).href = menuItemUrl;
                    viewAllItem.querySelector('a')!.textContent = `View All ${menuItemName}`;
                }
                viewAllItem.classList.remove('hidden');
            }

            this.tabletMegaMenuColumns.forEach((column, index) => {
                if (index !== nextLevel - 1) {
                    column.classList.remove('show-left-border');
                }
            });
        }
    }

    private handleTabletBackButtonClick(): void {
        if (this.currentTabletLevel > 1) {
            const previousLevel = this.currentTabletLevel - 1;
            this.showTabletMegaMenuLevel(previousLevel);

            const currentColumn = this.tabletMegaMenuColumns[this.currentTabletLevel - 1];
            if (currentColumn) {
                currentColumn.classList.remove('show-left-border');
            }

            if (previousLevel > 1) {
                const previousColumn = this.tabletMegaMenuColumns[previousLevel - 1];
                if (previousColumn) {
                    previousColumn.classList.add('show-left-border');
                }
            }

            this.currentTabletLevel = previousLevel;
        }
    }

    private showTabletMegaMenuLevel(level: number): void {
        this.currentTabletLevel = level;
        const isMobile = this.isMobile();

        this.tabletMegaMenuColumns.forEach((column, index) => {
            if (isMobile) {
                if (index === level - 1) {
                    column.style.display = 'block';
                    column.style.transform = 'translateX(0)';
                } else {
                    column.style.display = 'none';
                    column.style.transform = 'translateX(100%)';
                }
            } else {
                // Tablet screens (768px–992px)
                if (level === 3) {
                    if (index === 0) {
                        column.style.display = 'none';
                    } else if (index === 1 || index === 2) {
                        column.style.display = 'block';
                        column.style.transform = 'translateX(0)';
                    } else {
                        column.style.display = 'none';
                        column.style.transform = 'translateX(100%)';
                    }
                }
                else if (level === 4) {
                    if (index === 0 || index === 1) {
                        column.style.display = 'none';
                    } else if (index === 2 || index === 3) {
                        column.style.display = 'block';
                        column.style.transform = 'translateX(0)';
                    } else {
                        column.style.display = 'none';
                        column.style.transform = 'translateX(100%)';
                    }
                } else {
                    if (index < level) {
                        column.style.display = 'block';
                        column.style.transform = 'translateX(0)';
                    } else {
                        column.style.display = 'none';
                        column.style.transform = 'translateX(100%)';
                    }
                }
            }

            if (index === level - 1 && level > 1) {
                column.classList.add('show-left-border');
            } else {
                column.classList.remove('show-left-border');
            }
        });

        if (this.tabletBackButton) {
            this.tabletBackButton.style.visibility = (isMobile ? level > 1 : level > 2) ? 'visible' : 'hidden';
        }
    }

    private filterTabletSubmenuItems(parentId: string, level: number): void {
        const nextLevelColumn = this.tabletMegaMenuColumns[level - 1];
        const nextLevelItems = nextLevelColumn.querySelectorAll('li');
    
        let hasVisibleItems = false;
        nextLevelItems.forEach(item => {
            const itemParentId = item.getAttribute('data-parent-id');
            if (itemParentId === parentId) {
                (item as HTMLElement).style.display = 'block';
                hasVisibleItems = true;
            } else {
                (item as HTMLElement).style.display = 'none';
            }
        });
    
        if (!hasVisibleItems) {
            this.currentTabletLevel--;
        }
    }

    private resetTabletMegaMenu(): void {
        this.currentTabletLevel = 1;
        this.tabletMegaMenuColumns.forEach((column, index) => {
            if (index === 0) {
                column.style.display = 'block';
                column.style.transform = 'translateX(0)';
            } else {
                column.style.display = 'none';
                column.style.transform = 'translateX(100%)';
            }
        });
        if (this.tabletBackButton) {
            this.tabletBackButton.style.visibility = 'hidden';
        }
    }

    private showMegaMenu(item: HTMLElement): void {
        this.cancelHideMegaMenu();
        if (this.activeMenuItem === item) return;

        this.activeMenuItem = item;
        const menuName = item.querySelector('a')?.firstChild?.textContent?.trim().toLowerCase().replace(/\s+/g, '-');
        if (this.megaMenuContainer) {
            this.megaMenuContainer.style.display = 'block';
        }
        this.megaMenus.forEach(menu => {
            if (menu.dataset.menuItem === menuName) {
                menu.classList.add('active');
                this.resetSubMenus(menu);
            } else {
                menu.classList.remove('active');
            }
        });
    }

    private scheduleHideMegaMenu(): void {
        if (this.menuTimeout !== null) {
            clearTimeout(this.menuTimeout);
        }
        this.menuTimeout = window.setTimeout(() => {
            this.hideMegaMenu();
        }, 200);
    }

    private cancelHideMegaMenu(): void {
        if (this.menuTimeout !== null) {
            clearTimeout(this.menuTimeout);
            this.menuTimeout = null;
        }
    }

    private hideMegaMenu(): void {
        this.activeMenuItem = null;
        if (this.megaMenuContainer) {
            this.megaMenuContainer.style.display = 'none';
        }
        this.megaMenus.forEach(menu => menu.classList.remove('active'));
    }

    private showSubMenu(item: HTMLElement): void {
        const currentLevel = item.closest('.submenu-column');
        const nextLevel = currentLevel?.nextElementSibling as HTMLElement;

        if (currentLevel && nextLevel) {
            const nextLevelItems = nextLevel.querySelectorAll('li');
            nextLevelItems.forEach(li => (li as HTMLElement).style.display = 'none');

            const itemName = item.querySelector('a')?.firstChild?.textContent?.trim().toLowerCase().replace(/\s+/g, '-');
            const matchingItems = nextLevel.querySelectorAll(`li[data-parent="${itemName}"]`);
            matchingItems.forEach(li => (li as HTMLElement).style.display = 'block');

            currentLevel.querySelectorAll('li').forEach(li => li.classList.remove('active'));
            item.classList.add('active');

            nextLevel.classList.toggle('show-border', matchingItems.length > 0);

            if (currentLevel.classList.contains('level-1')) {
                const level3 = currentLevel.parentElement?.querySelector('.level-3') as HTMLElement;
                if (level3) {
                    level3.querySelectorAll('li').forEach(li => (li as HTMLElement).style.display = 'none');
                    level3.classList.remove('show-border');
                }
            }
        }
    }

    private resetSubMenus(megaMenu: Element): void {
        const columns = megaMenu.querySelectorAll('.submenu-column');
        columns.forEach((column, index) => {
            if (index === 0) {
                (column as HTMLElement).style.display = 'block';
                const firstItem = column.querySelector('li');
                if (firstItem) {
                    this.showSubMenu(firstItem as HTMLElement);
                }
            } else {
                (column as HTMLElement).querySelectorAll('li').forEach(li => (li as HTMLElement).style.display = 'none');
                column.classList.remove('show-border');
            }
        });

        const activeItems = megaMenu.querySelectorAll('.active');
        activeItems.forEach(item => item.classList.remove('active'));

        const secondColumn = megaMenu.querySelector('.submenu-column.level-2') as HTMLElement;
        if (secondColumn) {
            const visibleItems = secondColumn.querySelectorAll('li[style="display: block;"]');
            secondColumn.classList.toggle('show-border', visibleItems.length > 0);
        }
    }
}
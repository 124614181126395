export class Button {
    private element: HTMLButtonElement;
    private url: string;

    constructor(element: HTMLButtonElement) {
        this.element = element;
        this.url = this.element.dataset.url || '#';
        this.init();
    }

    private init(): void {
        this.element.addEventListener('click', this.handleClick.bind(this));
    }

    private handleClick(event: MouseEvent): void {
        event.preventDefault();
        window.location.href = this.url;
    }
}
export class HeroBannerWithCtaSlide {
    private element: HTMLElement;

    constructor(element: HTMLElement) {
        this.element = element;
        this.init();
    }

    private init(): void {

    }
}
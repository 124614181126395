export class ImageCtaThreeColumnCard {
  private element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;
    this.init();
  }

  private init(): void {

  }
}
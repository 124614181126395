import { HeroBannerWithCtaSlide } from "../../molecules/HeroBannerWithCtaSlide/HeroBannerWithCtaSlide";

export class HeroBannerWithCta {
    private element: HTMLElement;
    private slides: HeroBannerWithCtaSlide[];
    private prevButton: HTMLButtonElement | null;
    private nextButton: HTMLButtonElement | null;
    private indicators: HTMLElement[];
    private currentIndex: number = 0;

    constructor(element: HTMLElement) {
        this.element = element;
        this.slides = [];
        this.prevButton = this.element.querySelector('.prev');
        this.nextButton = this.element.querySelector('.next');
        this.indicators = Array.from(this.element.querySelectorAll('.indicator')).filter((el): el is HTMLElement => el instanceof HTMLElement);
        this.init();
    }

    private init(): void {
        const slideElements = this.element.querySelectorAll('.hero-banner-cta-slide');
        slideElements.forEach(slideElement => {
            if (slideElement instanceof HTMLElement) {
                this.slides.push(new HeroBannerWithCtaSlide(slideElement));
            }
        });

        if (this.slides.length > 0) {
            this.updateSlides();
            this.attachEventListeners();
        }
    }

    private updateSlides(): void {
        const slidesContainer = this.element.querySelector('.hero-banner-cta .slides');
        if (slidesContainer instanceof HTMLElement) {
            slidesContainer.style.transform = `translateX(-${this.currentIndex * 100}%)`;
        }
        this.updateIndicators();
        this.updateButtons();
    }

    private updateIndicators(): void {
        this.indicators.forEach((indicator, index) => {
            if (indicator instanceof HTMLElement) {
                indicator.classList.toggle('active', index === this.currentIndex);
            }
        });
    }

    private updateButtons(): void {
        if (this.prevButton) {
            this.prevButton.style.display = this.currentIndex > 0 ? 'block' : 'none';
        }
        if (this.nextButton) {
            this.nextButton.style.display = this.currentIndex < this.slides.length - 1 ? 'block' : 'none';
        }
    }

    private handlePrev = (): void => {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            this.updateSlides();
        }
    }

    private handleNext = (): void => {
        if (this.currentIndex < this.slides.length - 1) {
            this.currentIndex++;
            this.updateSlides();
        }
    }

    private goToSlide = (index: number): void => {
        if (index >= 0 && index < this.slides.length) {
            this.currentIndex = index;
            this.updateSlides();
        }
    }

    private attachEventListeners(): void {
        this.prevButton?.addEventListener('click', this.handlePrev);
        this.nextButton?.addEventListener('click', this.handleNext);
        this.indicators.forEach((indicator, index) => {
            if (indicator instanceof HTMLElement) {
                indicator.addEventListener('click', () => this.goToSlide(index));
            }
        });
    }
}
import { truncateText } from '../../../scripts/utils/truncateText';

export class ImageCtaTwoColumnCard {
    private element: HTMLElement;
    private descriptionElement: HTMLParagraphElement | null;
    private truncationLength: number = 200;

    constructor(element: HTMLElement) {
        this.element = element;
        this.descriptionElement = this.element.querySelector('.image-cta-two-column-card__content__text p');
        this.init();
    }

    private init(): void {
        this.updateTruncation();
        window.addEventListener('resize', this.updateTruncation.bind(this));
    }

    private updateTruncation(): void {
        const windowWidth = window.innerWidth;
        if (windowWidth < 768) {
            this.truncationLength = 120;
        } else if (windowWidth < 992) {
            this.truncationLength = 100;
        } else if (windowWidth < 1340) {
            this.truncationLength = 170;
        } else {
            this.truncationLength = 130;
        }
        this.truncateDescription();
    }

    private truncateDescription(): void {
        if (this.descriptionElement) {
            const originalText = this.descriptionElement.dataset.fullText ?? '';
            const truncatedText = truncateText(originalText, this.truncationLength);
            this.descriptionElement.textContent = truncatedText;
        }
    }
}
import { ImageCtaTwoColumnCard } from "../../molecules/ImageCtaTwoColumnCard/ImageCtaTwoColumnCard";

export class ImageCtaTwoColumn {
    private element: HTMLElement;
    private cards: ImageCtaTwoColumnCard[];

    constructor(element: HTMLElement) {
        this.element = element;
        this.cards = [];
        this.init();
    }

    private init(): void {
        const cardElements = this.element.querySelectorAll('[data-image-cta-two-column-card]');
        cardElements.forEach(cardElement => {
            if (cardElement instanceof HTMLElement) {
                this.cards.push(new ImageCtaTwoColumnCard(cardElement));
            }
        });
    }
}